import React, { Suspense, useEffect, useState } from 'react';
import { HashRouter, Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSpinner, useColorModes } from '@coreui/react';
import './scss/style.scss';
import { setAuth } from './utils/slices/authSlice';
import { URL } from './apiURL';
import { setLoading } from './utils/slices/layoutSlice';

const { VERIFY_TOKEN_URL } = URL;

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const dispatch = useDispatch();
  const [authChecked, setAuthChecked] = useState(false);

  const verifyAuth = async () => {
    dispatch(setLoading(true));
    try {
      const authToken = localStorage.getItem('token');
      if (authToken) {
        const response = await fetch(VERIFY_TOKEN_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        });

        if (response.ok && response.status === 200) {
          dispatch(setAuth(true));
        } else {
          localStorage.removeItem('token');
          console.error('Verification failed');
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setAuthChecked(true);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    verifyAuth();
  }, []);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.layout.loading);

  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme');
  const storedTheme = useSelector((state) => state.layout.theme);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
  }, [setColorMode, isColorModeSet, storedTheme]);

  if (loading || !authChecked) {
    return (
      <div className="pt-3 text-center">
        <CSpinner color="primary" variant="grow" />
      </div>
    );
  }

  return (
    <Router>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" replace />} />
          <Route path="/register" element={<Register />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="*" element={!isAuthenticated ? <Login /> : <DefaultLayout />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
